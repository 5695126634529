<template>
  <!-- <div class="container">
    <div class="row">
      <div class="col-12 py-4">
        <CompaniesList />
      </div>
    </div>
  </div> -->

  <router-view></router-view>
</template>
<script>
// import CompaniesList from './components/CompaniesList.vue';

export default {
  name: 'Company',
  // components: { CompaniesList },
};
</script>
